<template>
  <el-dialog
    title="编辑网络模型/数据集"
    :visible.sync="dialogEditFormVisible"
    width="80rem"
    :close-on-click-modal="false"
    ><el-divider>模型/数据集基本信息</el-divider>
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子性质"
            :label-width="formLabelWidth"
            prop="molecular_properties"
          >
            <el-input
              v-model="form.molecular_properties"
              placeholder="请输入分子性质"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="数据类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="form.type">
              <el-radio label="TRAINED_NETWORKS_WITH_DATA"
                >网络模型训练数据</el-radio
              >
              <el-radio label="DATA_SETS">数据集</el-radio>
            </el-radio-group>
          </el-form-item></el-col
        ></el-row
      >
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子总数"
            :label-width="formLabelWidth"
            prop="total_number_of_molecules"
          >
            <el-input
              v-model="form.total_number_of_molecules"
              placeholder="请输入分子总数"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="下载地址"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-input
              v-model="form.download_address"
              placeholder="Enter the download address"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-tag
              :type="
                form.status == 1
                  ? 'info'
                  : form.status == 2
                  ? 'success'
                  : form.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                form.status == 1
                  ? "待审核"
                  : form.status == 2
                  ? "已通过"
                  : form.status == 3
                  ? "未通过"
                  : "已下线"
              }}</el-tag
            ></el-form-item
          ></el-col
        ></el-row
      >
      <el-row>
        <el-col>
          <el-form-item
            label="详细信息"
            :label-width="formLabelWidth"
            prop="details_content"
          >
            <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEditCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleSave()"
        icon="el-icon-circle-check"
        >保 存</el-button
      >
    </div></el-dialog
  >
</template>
  
  <script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "NetworkModelEdit",
  components: { WangEditor },
  data() {
    return {
      formLabelWidth: "10rem",
      dialogEditFormVisible: false,
      isLoading: false,
      form: {
        molecular_properties: "",
        type: "",
        total_number_of_molecules: "",
        download_address: "",
        details_content: "",
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen(data) {
      this.dialogEditFormVisible = true;
      this.form = data;
      this.$nextTick(() => {
        this.$refs.WangEditor.initHtml(this.form.details_content);
      });
    },
    handleSave() {
      this.$store
        .dispatch("networkModel/edit", {
          un_code: this.form.un_code,
          molecular_properties: this.form.molecular_properties,
          type: this.form.type,
          total_number_of_molecules: this.form.total_number_of_molecules,
          download_address: this.form.download_address,
          details_content: this.form.details_content,
        })
        .then((res) => {
          this.$message.success("保存成功！");
          this.handleEditCancel();
        })
        .catch(() => {
          this.$message.error("保存失败！");
        });
    },
    handleEditCancel() {
      this.dialogEditFormVisible = false;
      this.$emit("getInfo");
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.details_content = content;
    },
  },
};
</script>
  
  <style>
</style>