<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>网络模型管理</span></el-col>
        <el-col :span="10">
          <el-input
            placeholder="请输入分子性质"
            v-model="keyWord"
            class="input-with-select"
            @keyup.enter.native="getInfo"
          >
            <el-button slot="append" icon="el-icon-search" @click="getInfo"></el-button>
          </el-input>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="success"
            round
            icon="el-icon-circle-plus-outline"
            @click="Add()"
            :loading="isLoading"
            >添加</el-button
          ></el-col
        ></el-row
      >
    </div>
    <NetworkModelSelect ref="NetworkModelSelect" />
    <NetworkModelAdd @getInfo="getInfo" ref="NetworkModelAdd" />
  </el-card>
</template>
  
  <script>
import NetworkModelSelect from "./NetworkModelSelect/index.vue";
import NetworkModelAdd from "./NetworkModelAdd/index.vue";
export default {
  components: { NetworkModelSelect, NetworkModelAdd },
  data() {
    return {
      isLoading: false,
      //模糊搜索关键词
      keyWord:""
    };
  },
  methods: {
    Add() {
      this.$refs.NetworkModelAdd.handleOpen();
    },
    getInfo() {
      this.$refs.NetworkModelSelect.getInfo(this.keyWord);
    },
  },
};
</script>
  
  <style scoped>
</style>