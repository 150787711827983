<template>
  <el-dialog
    title="添加网络模型/数据集"
    :visible="dialogAddFormVisible"
    width="70rem"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子性质"
            :label-width="formLabelWidth"
            prop="molecular_properties"
          >
            <el-input
              v-model="form.molecular_properties"
              placeholder="请输入分子性质"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="数据类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="form.type">
              <el-radio label="TRAINED_NETWORKS_WITH_DATA">网络模型训练数据</el-radio>
              <el-radio label="DATA_SETS">数据集</el-radio>
            </el-radio-group>
          </el-form-item></el-col
        ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子总数"
            :label-width="formLabelWidth"
            prop="total_number_of_molecules"
          >
            <el-input
              v-model="form.total_number_of_molecules"
              placeholder="请输入分子总数"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="下载地址"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-input
              v-model="form.download_address"
              placeholder="Enter the download address"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row>
        <el-col>
          <el-form-item
            label="详细信息"
            :label-width="formLabelWidth"
            prop="details_content"
          >
            <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAddCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleAdd()"
        icon="el-icon-circle-check"
        >创 建</el-button
      >
    </div>
  </el-dialog>
</template>
  
  <script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "NetworkModelAdd",
  components: { WangEditor },
  data() {
    return {
      dialogAddFormVisible: false,
      isLoading: false,
      formLabelWidth: "10rem",
      form: {
        molecular_properties: "",
        type: "TRAINED_NETWORKS_WITH_DATA",
        total_number_of_molecules: "",
        download_address: "",
        details_content: "",
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen() {
      this.dialogAddFormVisible = true;
      this.$refs.WangEditor.initHtml();
    },
    handleAdd() {
      this.isLoading = true;
      this.$store
        .dispatch("networkModel/add", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleAddCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    handleAddCancel() {
      this.form = {};
      this.dialogAddFormVisible = false;
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.details_content = content;
    },
  },
};
</script>
  
  <style>
</style>