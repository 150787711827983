<template>
  <el-dialog
    title="审核网络模型/数据集"
    :visible="dialogAuditFormVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-descriptions title="网络模型/数据集基本信息" :column="2" border>
      <el-descriptions-item label="分子性质" label-class-name="my-label">{{form.molecular_properties}}</el-descriptions-item>
      <el-descriptions-item label="数据类型" label-class-name="my-label">{{form.type}}</el-descriptions-item>
      <el-descriptions-item label="分子数总数" label-class-name="my-label">{{form.total_number_of_molecules}}</el-descriptions-item>
      <el-descriptions-item label="下载地址" label-class-name="my-label">{{form.download_address}}</el-descriptions-item>
      <el-descriptions-item label="详细信息" label-class-name="my-label"><div v-html="form.details_content"></div></el-descriptions-item>
    </el-descriptions>
    <el-row class="remark_row">
      <el-card>
        <div slot="header"><b>审核意见</b></div>
        <el-input
          type="textarea"
          rows="3"
          placeholder="请输入审核意见"
          v-model="remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </el-card>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAuditCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="danger"
        :loading="isLoading"
        @click="handleAudit(3)"
        icon="el-icon-remove-outline"
        >驳回</el-button
      >
      <el-button
        type="success"
        :loading="isLoading"
        @click="handleAudit(2)"
        icon="el-icon-circle-check"
        >通过</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "NetworkModelAudit",
  data() {
    return {
      dialogAuditFormVisible: false,
      form: {},
      //审核意见
      remark: "",
      isLoading: false,
    };
  },
  methods: {
    handleOpen(form) {
      this.form = form
      this.dialogAuditFormVisible = true;
    },
    handleAuditCancel() {
      this.dialogAuditFormVisible = false;
      this.remark = "";
    },
    //保存审核的信息
    handleAudit(status) {
      var statusText = status == 2 ? "通过" : "驳回";
      this.$confirm("确认" + statusText + "？")
        .then((_) => {
          this.$store
            .dispatch("networkModelAuditRecord/add", {
              un_code: this.form.un_code,
              status: status,
              remark: this.remark,
            })
            .then((res) => {
              this.$emit("getInfo");
              this.$message.success(res.msg);
              this.handleAuditCancel();
            })
            .catch(() => {
              this.$message.error("保存失败！");
            });
        })
        .catch((_) => {});
    },
    handleAuditCancel() {
      this.dialogAuditFormVisible = false;
      this.remark = "";
    },
  }
};
</script>

<style>
/*不能用scoped*/
.my-label {
    width: 150px;
  }
</style>