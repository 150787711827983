var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.infoList,"border":""}},[_c('el-table-column',{attrs:{"prop":"molecular_properties","label":"分子性质"}}),_c('el-table-column',{attrs:{"prop":"type","label":"数据类型"}}),_c('el-table-column',{attrs:{"prop":"status","width":"160","label":"网络模型/数据集状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status == 1
              ? 'info'
              : scope.row.status == 2
              ? 'success'
              : scope.row.status == 3
              ? 'warning'
              : 'danger'}},[_vm._v(_vm._s(scope.row.status == 1 ? "待审核" : scope.row.status == 2 ? "已通过" : scope.row.status == 3 ? "未通过" : "已下线"))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"210"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_vm._v("编辑")]),(scope.row.status == 1 || scope.row.status == 3)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleAudit(scope.$index, scope.row)}}},[_vm._v("审核")]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[5, 10, 15, 20],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('NetworkModelEdit',{ref:"NetworkModelEdit",attrs:{"form":_vm.form},on:{"getInfo":_vm.getInfo}}),_c('NetworkModelAudit',{ref:"NetworkModelAudit",attrs:{"form":_vm.form},on:{"getInfo":_vm.getInfo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }