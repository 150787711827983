<template>
  <div>
    <el-table :data="infoList" border style="width: 100%">
      <el-table-column prop="molecular_properties" label="分子性质">
      </el-table-column>
      <el-table-column prop="type" label="数据类型">
      </el-table-column>
      <el-table-column prop="status" width="160" label="网络模型/数据集状态"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'
            "
            >{{
              scope.row.status == 1
                ? "待审核"
                : scope.row.status == 2
                ? "已通过"
                : scope.row.status == 3
                ? "未通过"
                : "已下线"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.status == 1 || scope.row.status == 3"
            type="primary"
            @click="handleAudit(scope.$index, scope.row)"
            >审核</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <NetworkModelEdit :form="form" ref="NetworkModelEdit" @getInfo="getInfo" />
    <NetworkModelAudit
      :form="form"
      ref="NetworkModelAudit"
      @getInfo="getInfo"
    />
  </div>
</template>
    
    <script>
import NetworkModelEdit from "../NetworkModelEdit/index.vue";
import NetworkModelAudit from "../NetworkModelAudit/index.vue";
export default {
  name: "NetworkModelSelect",
  components: {
    NetworkModelEdit,
    NetworkModelAudit,
  },
  data() {
    return {
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo(keyWord) {
      this.$store
        .dispatch("networkModel/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
          keyWord: keyWord,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //编辑列函数
    handleEdit(index, row) {
      this.form = row;
      this.$refs.NetworkModelEdit.handleOpen(this.form);
    },
    //审核函数
    handleAudit(index, row) {
      this.form = row;
      this.$refs.NetworkModelAudit.handleOpen(this.form);
    },
    //删除列函数
    handleDelete(index, row) {
      this.$confirm("此操作将删除该网络模型/数据集相关信息 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("networkModel/del", { un_code: row.un_code }).then((res) => {
            this.$message.success(res.msg);
            this.getInfo();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
    
    <style>
</style>